import * as React from 'react';
import { Link } from 'gatsby';
import _ from 'lodash';
import { FluidImage } from '../gatsby-image';
import {
  PostCardWrapper,
  PostPreview,
  PostDetails,
  PostDate,
  PostTitle,
  Excerpt,
  PostContent,
  PostTags,
} from './post-card.style';

interface PostCardProps {
  image?: any;
  title: string;
  description?: string;
  url: string;
  date?: string;
  tags?: [];
  className?: string;
  placeholderBG?: string;
}

const PostCard: React.FunctionComponent<PostCardProps> = ({
  image,
  title,
  description,
  url,
  date,
  tags,
  className,
  placeholderBG,
  ...props
}) => {
  // Add all classs to an array
  const addAllClasses = ['post_card'];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  const [day, month] = date ? date?.split("/") : [];

  return (
    <PostCardWrapper className={addAllClasses.join(' ')} {...props}>
      {!!image && (
        <PostPreview className="post_preview">
          <Link to={`/${url}`}>
            <FluidImage blok={image} alt="post preview" backgroundColor={placeholderBG} />
          </Link>
        </PostPreview>
      )}

      <PostDetails className="post_details">
        {date && (
          <PostDate
            className="post_date"
          >
            {day}
            <span>{month}</span>
          </PostDate>
        )}

        <PostContent className="post_content">
          <PostTitle className="post_title">
            <Link to={`/${url}`}>{title}</Link>
          </PostTitle>
          {description && (
            <Excerpt
              dangerouslySetInnerHTML={{
                __html: description,
              }}
              className="excerpt"
            />
          )}

          {tags == null ? null : (
            <PostTags className="post_tags">
              {tags.map((tag: string, index: number) => (
                <Link key={index} to={`/tags/${_.kebabCase(tag)}/`}>
                  {`#${tag}`}
                </Link>
              ))}
            </PostTags>
          )}
        </PostContent>
      </PostDetails>
    </PostCardWrapper>
  );
};

export default PostCard;
