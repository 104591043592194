import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import PostCard from '../components/post-card/post-card';
import Newsletter from '../components/newsletter/newsletter';
import SEO from '../components/seo';
import { TagPostsWrapper, TagPageHeading, TagName } from './templates.style';
import parseContent from '../utils/parseContent';

const Tags = ({ pageContext, ...props }: any) => {
  const { tag } = pageContext;
  const { nodes, totalCount } = props.data.blogPosts;
  const newsLetter = parseContent(props.data.newsletter.nodes[0].content);


  return (
    <Layout>
      <SEO title={tag} description={`A collection of ${totalCount} post`} />

      <TagPostsWrapper>
        <TagPageHeading>
          <TagName>{tag}</TagName>
          {`A collection of ${totalCount} post`}
        </TagPageHeading>
        {nodes.map((post: any) => {
          const content = parseContent(post.content);

          return (
            <PostCard
              key={post.id}
              title={post.name}
              url={post.full_slug}
              description={content.description}
              date={post.published_at}
              tags={post.tag_list}
            />
          );
        })}
      </TagPostsWrapper>
      <Newsletter {...newsLetter} />
    </Layout>
  );
};

export default Tags;

export const pageQuery = graphql`
  query($tag: String) {
   blogPosts: allStoryblokEntry(
        filter: {field_component: {eq: "blog_post"}, tag_list: {in: [$tag] }}
        limit: 1000
        sort: {fields: published_at, order: DESC}
      ) {
        nodes {
          full_slug
          name
          id
          content
          tag_list
          published_at(formatString: "DD/MMMM")
        }
        totalCount
      }
      newsletter: allStoryblokEntry(filter: {field_component: {eq: "newsletter"}}) {
        nodes {
          id
          content
        }
      }
  }
`;
